import {
  DashboardWidget,
  PieDataItem,
  WidgetType,
} from '../../../utils/types/visualDashboard.type';
import { getColor } from '../helpers/Chart.utils';
import { PageSummaryItem } from '../VisualDashboard';
import { VisualDashboardType } from '../VisualDashboard.constants';
import { isFundSummaryItemArray } from '../VisualDashboard.hooks';
import { PieChart } from './PieChart';

export function getPieChartData(
  summaryItems: PageSummaryItem[],
  pageType: string,
  dashboardWidgets: DashboardWidget[],
  currency: string
) {
  let pieDataItems: PieDataItem[] = [];
  let pageSummaryItems = [];
  let chartWidgets = [];

  switch (pageType) {
    case VisualDashboardType.CapitalAccounts:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);
      const accumulated = pageSummaryItems.reduce((acc: any, item) => {
        const { fundId, name, committed } = item;
        const key = `${fundId}-${name}`;

        if (!acc[key]) {
          acc[key] = { id: fundId, value: 0, label: name };
        }
        acc[key].value += committed;
        return acc;
      }, {});

      pieDataItems = Object.values(accumulated).map((item: any, index) => ({
        id: item.id,
        value: item.value,
        label: item.label,
        color: getColor(index),
      }));
      break;
    case VisualDashboardType.Fund:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);

      pieDataItems = pageSummaryItems.map<PieDataItem>((fs, index) => {
        return {
          id: fs.id,
          value: fs.committed,
          label: fs.name,
          color: getColor(index),
        } as PieDataItem;
      });
      break;
  }

  chartWidgets = dashboardWidgets
    ?.filter(
      (widget: DashboardWidget) =>
        widget.widgetType === WidgetType.Charts && widget.visible
    )
    .map((widget: DashboardWidget) => {
      let display = false;
      let chart: JSX.Element | undefined = undefined;

      switch (widget.name) {
        case 'committedByFund':
          chart = <PieChart pieDataItems={pieDataItems} currency={currency} />;
          if (pieDataItems.length > 0) display = true;
          break;
      }

      return {
        index: widget.index,
        name: widget.name,
        title: widget.title,
        visible: widget.visible,
        widgetType: widget.widgetType,
        chart: chart,
        display: display,
      };
    })
    .sort((a, b) => a.index - b.index);

  const filteredCharts = chartWidgets.filter(
    (chart) => chart.chart !== undefined
  );

  return { currency: currency, charts: filteredCharts };
}
