import { Box, Grid, Stack, styled } from '@mui/material';

export const MainGridStyled = styled(Grid)(() => ({
  marginRight: 20,
}));

export const ColorBoxStyled = styled(Stack)(() => ({
  color: 'white',
  borderRadius: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CountBoxStyled = styled(Box)(() => ({
  marginRight: '5px',
}));

export const LabelBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const StackStyled = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}));
