import { Box, Chip, styled } from '@mui/material';

import {
  ViewOption,
  ViewOptionTypes,
} from '../../../components/Visualizations/viewOptionsTabs/ViewOptionsTabs';

interface CapitalAccountWrapper {
  wrapperHeight: number | undefined;
}

export const CapitalAccountsWrapper = styled(Box)<{}>(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr ',
}));

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridTemplateColumns: '15% 61% 23%',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

export const CapitalAccountsDataWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  overflow: 'auto',
  maxHeight: '100%',
  gridTemplateColumns: '1fr',
}));

export const CapitalAccountsDataBox = styled(Box)<{
  viewState: ViewOptionTypes;
}>(({ theme, viewState }) => ({
  position: 'absolute',
  display: 'grid',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr',
  gridTemplateRows:
    viewState === ViewOption.DASHBOARD
      ? 'auto'
      : 'minmax(0, auto) minmax(40%, 1fr)',
}));

export const VisualizationsBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gap: '8px',
  overflow: 'auto',
}));

export const DataGridBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gap: '8px',
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '& #btn_publish_quarters': {
    marginRight: theme.spacing(1),
  },
}));

export const CellBox = styled(Box)(() => ({
  width: '100%',
}));

export const CapitalAccountsWrapperOLD = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'wrapperHeight',
})<CapitalAccountWrapper>(({ theme, wrapperHeight = 'auto' }) => ({
  overflowY: 'auto',
  marginTop: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: wrapperHeight,
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));
