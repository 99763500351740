import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DefaultizedPieValueType } from '@mui/x-charts';
import { useDrawingArea } from '@mui/x-charts/hooks';
import {
  pieArcLabelClasses,
  PieChart as MuiPieChart,
} from '@mui/x-charts/PieChart';
import React, { FC } from 'react';

import { generateUUID } from '../../../utils/helpers/uuidGenerator';
import { PieDataItem } from '../../../utils/types/visualDashboard.type';
import { getChartLegend } from '../ChartLegend/ChartLegend';
import { chartsTooltipValueFormatter } from '../helpers/Chart.utils';
import {
  LegendGrid,
  PieChartAndLegendContainer,
  PieChartGrid,
  pieChartStyles,
  PieChartTitle,
  PieChartWrapper,
  pieParams,
} from './PieChart.styles';

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();

  return (
    <>
      <StyledText x={left + width / 2} y={top + height / 2 - 16}>
        {children}
      </StyledText>
      <StyledText x={left + width / 2} y={top + height / 2 + 16}>
        Total
      </StyledText>
    </>
  );
}

interface PieProps {
  pieDataItems: PieDataItem[];
  currency: string;
}

export const PieChart: FC<PieProps> = ({ pieDataItems, currency }) => {
  if (!pieDataItems?.length) return <></>;

  const pieTotal = pieDataItems.reduce(
    (total, item) => total + ((item.value as number) ?? 0),
    0
  );
  const totalStr = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'currency',
    currency: currency,
  }).format(pieTotal);

  const legend = getChartLegend(pieDataItems);

  return (
    <PieChartWrapper container>
      <Grid>
        <PieChartTitle>Commitments by Fund</PieChartTitle>
      </Grid>
      <PieChartAndLegendContainer container direction="row">
        <LegendGrid item xs={12} sm={6}>
          {legend}
        </LegendGrid>
        <PieChartGrid item xs={12} sm={6}>
          <MuiPieChart
            series={[
              {
                data: pieDataItems,
                innerRadius: '60%',
                arcLabel: (params: DefaultizedPieValueType) => '',
                valueFormatter: ({ value }) =>
                  chartsTooltipValueFormatter(value, currency),
                cx: '50%',
                cy: '50%',
              },
            ]}
            slotProps={{
              legend: { hidden: true },
              popper: {
                sx: pieChartStyles.popper,
              },
            }}
            sx={pieChartStyles.arcLabel}
            {...pieParams}
          >
            <PieCenterLabel>{totalStr}</PieCenterLabel>
          </MuiPieChart>
        </PieChartGrid>
      </PieChartAndLegendContainer>
    </PieChartWrapper>
  );
};
