import { Box, Fab, keyframes, styled } from '@mui/material';

const EditableWidgetAnimation = (size: 'sm' | 'md' | 'lg') => {
  const animation = {
    sm: keyframes`
        0%, 100% {
          transform: rotate(-0.5deg) translateX(0px);
        }
        50% {
          transform: rotate(0.5deg) translateX(0px);
        }
      `,
    md: keyframes`
        0%, 100% {
          transform: rotate(-0.06deg) translateX(0px);
        }
        50% {
          transform: rotate(0.06deg) translateX(0px);
        }
      `,
    lg: keyframes`
        0%, 100% {
          transform: rotate(-0.1deg) translateX(0px);
        }
        50% {
          transform: rotate(0.1deg) translateX(0px);
        }
      `,
  };

  return animation[size];
};

export const DragAndDropContainer = styled(Box)({
  padding: '1rem',
  marginTop: '1rem',
  paddingRight: '32px',
  display: 'flex',
  gap: '16px',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center',
});

export const DraggableItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isInEditMode',
})<{
  isInEditMode?: boolean;
}>(({ theme, isInEditMode }) => ({
  position: 'relative',
  cursor: `${isInEditMode ? `move` : ''}`,
  width: 'fit-content',
  margin: '0px !important',
  '&.dragging': {
    opacity: 0.5,
  },
  animation: `${
    isInEditMode
      ? `${EditableWidgetAnimation('sm')} 0.5s ease-in-out infinite`
      : ''
  }`,
}));

export const VisibilityButton = styled(Fab, {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{
  isVisible?: boolean;
}>(({ theme, isVisible }) => ({
  position: 'absolute',
  top: '8px',
  right: '8px',
  width: '21px',
  height: '21px',
  minWidth: '21px',
  minHeight: '21px',
  backgrounColor: `${theme.palette.grey}`,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#FF666680',
  },
}));
