import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

import { DataWrapperBox } from '../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import Button from '../../../components/Buttons/Button/Button';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import DataGrid from '../../../components/DataGrid/DataGrid';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import Progress from '../../../components/Progress/ProgressModal/ProgressModal';
import {
  ViewOption,
  ViewOptionsTabs,
} from '../../../components/Visualizations/viewOptionsTabs/ViewOptionsTabs';
import { VisualDashboard } from '../../../components/Visualizations/VisualDashboard';
import { VisualDashboardType } from '../../../components/Visualizations/VisualDashboard.constants';
import RoutingPaths from '../../../core/routing/routingPaths';
import useWindowSize from '../../../utils/hooks/useWindowSize.hook';
import PublishQuartersView from '../publishQuarters/PublishQuartersView';
import StatementReportView from '../statementReport/StatementReportView';
import TransactionHistory from '../transactionHistory/TransactionHistory';
import {
  ButtonBox,
  CapitalAccountsDataBox,
  CapitalAccountsDataWrapper,
  CapitalAccountsWrapper,
  DataGridBox,
  HeaderRow,
  IconBox,
  VisualizationsBox,
} from './CapitalAccountList.styles';
import { useCapitalAccounts } from './useCapitalAccounts.hooks';

const CLIENT_PORTAL_DOWNLOAD_SUBTITLE =
  'The system is currently generating your reports. Depending on the number of reports, the process may take several minutes to finish. Please check your email for the report links.';
const ADMIN_PORTAL_DOWNLOAD_SUBTITLE =
  "The system is currently generating reports for each account that was selected. Depending on the number selected, the process may take several minutes to finish. Please check the 'My Downloads' page to check the status of the import.";

const CapitalAccountList: React.FC = (): ReactElement => {
  const {
    capitalAccountsList,
    capitalAccountsVisualizationData,
    headerList,
    setHeaderList,
    isLoadingList,
    page,
    setIsLastPage,
    isLoading,
    hasOneFundOnly,
    selectedFund,
    setSelectedFund,
    onStatementReportViewClose,
    onTransactionHistoryClose,
    handleOnViewClick,
    handleCellClick,
    selectedTransaction,
    setSelectedTransaction,
    columnsList,
    handleTabChange,
    handleFilter,
    selectedCapAccRowIds,
    setSelectedRows,
    bulkActions,
    handleBulkAction,
    showExportPdfCapAccReportPdfConfirm,
    closeConfirmDialog,
    onPublishQuartersViewClose,
    onPublishQuartersViewOpen,
    openPublishQuarters,
    isAdmin,
    onNextPage,
    isClientPortalLogin,
    setViewState,
    viewState,
    visualizationsEnabled,
    selectedCurrencyTab,
    setSelectedCurrencyTab,
  } = useCapitalAccounts();

  const pinnedColumns = {
    right: ['quarter', 'action'],
  };

  return (
    <CapitalAccountsWrapper id="cap_acc_list_main" role="main">
      <Progress
        id="cap_acc_loading"
        showProgress={isLoadingList || isLoading}
      />
      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">Capital Accounts</Typography>
          {visualizationsEnabled && (
            <ViewOptionsTabs
              viewOptions={[
                ViewOption.DASHBOARD,
                ViewOption.HYBRID,
                ViewOption.DATA_VIEW,
              ]}
              viewState={viewState}
              setViewState={setViewState}
            />
          )}
        </IconBox>
        <ButtonBox>
          {isAdmin && (
            <Button
              id="btn_publish_quarters"
              variant="contained"
              text="Publish Quarters"
              color={'primary'}
              onClick={() => onPublishQuartersViewOpen()}
            />
          )}
          <SplitButton
            id={'btn_bulk_action_options'}
            options={bulkActions}
            hidden={false}
            handleOptionClick={handleBulkAction}
            ariaLabelMessage="Select bulk action option"
          />
        </ButtonBox>
      </HeaderRow>
      <CapitalAccountsDataWrapper id="CapitalAccountsDataWrapper">
        <CapitalAccountsDataBox
          id="CapitalAccountsDataBox"
          viewState={viewState}
        >
          <VisualizationsBox>
            {visualizationsEnabled &&
              viewState !== ViewOption.DATA_VIEW &&
              capitalAccountsVisualizationData.length > 0 &&
              capitalAccountsVisualizationData[0].items.length > 0 && (
                <VisualDashboard
                  pageType={VisualDashboardType.CapitalAccounts}
                  pageSummary={capitalAccountsVisualizationData}
                  allItemsSelected={false}
                  selectedCurrencyTab={selectedCurrencyTab}
                  setSelectedCurrencyTab={setSelectedCurrencyTab}
                  viewState={viewState}
                />
              )}
          </VisualizationsBox>
          <DataGridBox>
            {viewState !== ViewOption.DASHBOARD && (
              <DataGrid
                id="capitalAccounts_data_grid"
                dataList={capitalAccountsList || []}
                headerList={headerList}
                noDataMessage={
                  isLoadingList || isLoading ? 'Fetching Data...' : undefined
                }
                minHeight="60px"
                autoHeight={false}
                activeHeaderFields={6}
                getRowClassName={(params) => {
                  return params?.row?.isChild
                    ? 'grouped-row'
                    : params?.row?.isParent
                    ? 'group-parent'
                    : '';
                }}
                onCellClick={handleCellClick}
                handleFilter={handleFilter}
                selectionModel={selectedCapAccRowIds}
                setSelectionModel={setSelectedRows}
                // onNextPage={onNextPage}
                hideColumnToggleAction
                hideSelectAll={true}
                pinnedColumns={pinnedColumns}
              />
            )}
          </DataGridBox>
        </CapitalAccountsDataBox>
      </CapitalAccountsDataWrapper>
      {!!selectedFund.fundId &&
        !!selectedFund.investorId &&
        !!selectedFund.quarter && (
          <StatementReportView
            onStatementReportViewClose={onStatementReportViewClose}
            resetPaginationAndFetch={() => {}}
            fund={selectedFund}
            setSelectedTransaction={setSelectedTransaction}
          />
        )}

      {!!selectedTransaction.fundId &&
        !!selectedTransaction.investorId &&
        !!selectedTransaction.quarter && (
          <TransactionHistory
            onTransactionHistoryClose={onTransactionHistoryClose}
            transaction={selectedTransaction}
            columnsList={columnsList || []}
            handleTabChange={handleTabChange}
          />
        )}
      {openPublishQuarters && (
        <PublishQuartersView
          onPublishQuartersViewClose={onPublishQuartersViewClose}
        />
      )}
      <ConfirmationDialog
        open={showExportPdfCapAccReportPdfConfirm}
        onClose={closeConfirmDialog}
        id="export_all_capAcc_Pdf_report"
        actions={
          isClientPortalLogin
            ? [
                {
                  label: 'Close',
                  onClick: closeConfirmDialog,
                  id: 'close_dialog_modal_button',
                  variant: 'outlined',
                  color: 'inherit',
                },
              ]
            : [
                {
                  label: 'My Downloads',
                  linkTo: RoutingPaths.DownloadHistory,
                  id: 'goto_downloads_modal_button',
                  variant: 'contained',
                  color: 'primary',
                },
                {
                  label: 'Close',
                  onClick: closeConfirmDialog,
                  id: 'close_dialog_modal_button',
                  variant: 'outlined',
                  color: 'inherit',
                },
              ]
        }
        content={
          isClientPortalLogin
            ? CLIENT_PORTAL_DOWNLOAD_SUBTITLE
            : ADMIN_PORTAL_DOWNLOAD_SUBTITLE
        }
        title={
          isClientPortalLogin
            ? 'Downloading Capital Accounts'
            : 'Downloading capital accounts...'
        }
      />
    </CapitalAccountsWrapper>
  );
};

export default React.memo(CapitalAccountList);
