import { CurrencyFormat } from '../../../utils/helpers/format.helper';
import {
  CapitalAccountSummaryItem,
  DashboardWidget,
  FundSummaryItem,
  SummaryCardItem,
  SummaryCardListItem,
  SummaryCards,
  WidgetType,
} from '../../../utils/types/visualDashboard.type';
import { PageSummaryItem } from '../VisualDashboard';
import { VisualDashboardType } from '../VisualDashboard.constants';
import { isFundSummaryItemArray } from '../VisualDashboard.hooks';

export function getSummaryCards(
  pageSummaryItems: PageSummaryItem[],
  currency: string,
  pageType: string,
  dashboardWidgets: DashboardWidget[],
  allItemsSelected: boolean
) {
  function getCardValue(value: number) {
    const roundedVal = Math.round(value);

    const formattedStringValue = CurrencyFormat(currency, 0).format(
      roundedVal === 0 ? 0 : roundedVal
    );

    return formattedStringValue;
  }

  function formatMetric(value: number, divisor: number, suffix: string) {
    let result;

    if (suffix === '%') {
      result = Math.round((divisor ? value / divisor : 0) * 100);
      return `${result === 0 ? 0 : result}${suffix}`;
    } else {
      result = (divisor ? value / divisor : 0).toFixed(2);
      return `${parseFloat(result) === 0 ? '0.00' : result}${suffix}`;
    }
  }

  const summaryCardDataList: SummaryCardListItem[] = [];

  switch (pageType) {
    case VisualDashboardType.CapitalAccounts:
    case VisualDashboardType.Fund:
      const fundSummaryItems: FundSummaryItem[] =
        isFundSummaryItemArray(pageSummaryItems);

      const calculateTotal = (field: keyof FundSummaryItem) =>
        fundSummaryItems.reduce(
          (total, item) => total + ((item[field] as number) ?? 0),
          0
        );

      const capitalCommittedTotal = calculateTotal('committed');
      const contributionsTotal = calculateTotal('contributions');
      const unfundedTotal = calculateTotal('unfunded');
      const distributionsTotal = calculateTotal('distribution');
      const valueTotal = calculateTotal('value');

      if (pageType === VisualDashboardType.Fund) {
        summaryCardDataList.push({
          name: 'totalFunds',
          value: pageSummaryItems.length.toString(),
        });
      }
      summaryCardDataList.push({
        name: 'rvpi',
        value: formatMetric(valueTotal, contributionsTotal, 'x'),
      });
      summaryCardDataList.push({
        name: 'dpi',
        value: formatMetric(distributionsTotal, contributionsTotal, 'x'),
      });
      summaryCardDataList.push({
        name: 'tvpi',
        value: formatMetric(
          valueTotal + distributionsTotal,
          contributionsTotal,
          'x'
        ),
      });
      summaryCardDataList.push({
        name: 'percentRemaining',
        value: formatMetric(unfundedTotal, capitalCommittedTotal, '%'),
      });
      summaryCardDataList.push({
        name: 'percentCalled',
        value: formatMetric(contributionsTotal, capitalCommittedTotal, '%'),
      });
      summaryCardDataList.push({
        name: 'moic',
        value: formatMetric(
          valueTotal + distributionsTotal,
          capitalCommittedTotal,
          'x'
        ),
      });
      summaryCardDataList.push({
        name: 'capitalCommitted',
        value: getCardValue(capitalCommittedTotal),
      });
      summaryCardDataList.push({
        name: 'funded',
        value: getCardValue(contributionsTotal),
      });
      summaryCardDataList.push({
        name: 'unfunded',
        value: getCardValue(unfundedTotal),
      });
      summaryCardDataList.push({
        name: 'distributions',
        value: getCardValue(distributionsTotal),
      });
      summaryCardDataList.push({
        name: 'nav',
        value: getCardValue(valueTotal),
      });
      break;
  }

  const summaryCardItems: SummaryCardItem[] = dashboardWidgets
    .filter((widget) => widget.widgetType === WidgetType.SummaryCard)
    .map((dw) => {
      const data = summaryCardDataList.find(
        (cardData) => cardData.name === dw.name
      );

      const title =
        dw.name === 'totalFunds' && pageType === VisualDashboardType.Fund
          ? `${dw.title} ${allItemsSelected ? '' : 'Selected'}`
          : dw.title;

      return {
        index: dw.index,
        title: title,
        value: data ? data.value : '',
        visible: dw.visible,
        name: dw.name,
      };
    })
    .sort((a, b) => a.index - b.index);

  return {
    currencyCode: currency,
    summaryCards: summaryCardItems,
  };
}
