export type DashboardWidget = {
  index: number;
  name: string;
  title: string;
  visible: boolean;
  widgetType: WidgetType;
  chart?: JSX.Element;
  display?: boolean;
};

export type ChartWidgets = {
  charts: DashboardWidget[];
  currency: string;
};

export enum WidgetType {
  SummaryCard = 'summary_card',
  EntityTypeByFund = 'entity_type_counts',
  Charts = 'charts',
}

export type SummaryCardItem = {
  title: string;
  name: string;
  index: number;
  value: string;
  visible: boolean;
};

export type SummaryCards = {
  currencyCode: string;
  summaryCards: SummaryCardItem[];
};

export type FundSummaryItem = {
  id: string;
  name: string;
  currencyCode: string;
  percentCalled: number;
  quarterAsOf: string;
  entityType: string;
  committed: number;
  contributions: number;
  unfunded: number;
  distribution: number;
  value: number;
  fundId?: string;
};

export type CapitalAccountSummaryItem = {
  id: string;
  name: string;
  currencyCode: string;
  percentCalled: number;
  quarterAsOf: string;
  entityType: string;
  committed: number;
  contributions: number;
  unfunded: number;
  distribution: number;
  value: number;
  fundId?: string;
  inceptionDate?: string;
};

export type SummaryCardListItem = {
  name: string;
  title?: string;
  visible?: boolean;
  index?: number;
  value: string;
};

export type PieDataItem = {
  id: string;
  value: number;
  label: string;
  color: string;
};
