import { Box, Grid, Paper, Stack } from '@mui/material';
import { FC, useContext, useState } from 'react';

import { AppContext } from '../../../core/context/appContextProvider';
import { getFundEntityTypeList } from '../../../services/fund.service';
import { generateUUID } from '../../../utils/helpers/uuidGenerator';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { getColor } from '../helpers/Chart.utils';
import { PageSummaryItem } from '../VisualDashboard';
import {
  ColorBoxStyled,
  CountBoxStyled,
  LabelBox,
  MainGridStyled,
} from './EntityTypeCounts.styles';

export type TypeCountItem = {
  id: string;
  label: string;
  count: number;
};

type EntityTypeCountsProps = {
  title: string;
  typeCountItems: TypeCountItem[];
};

export const EntityTypeCounts: FC<EntityTypeCountsProps> = ({
  title,
  typeCountItems,
}) => {
  return (
    <MainGridStyled container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
      <Grid item xs={1}>
        {title}
      </Grid>
      <Grid item xs={11}>
        <Stack direction="row" justifyContent="center" alignItems="flex-start">
          {typeCountItems.map((item, index) => {
            let widthPercent =
              (item.count /
                typeCountItems.reduce((acc, curr) => acc + curr.count, 0)) *
              100;

            if (widthPercent <= 0) widthPercent = 1;

            return (
              <CountBoxStyled width={`${widthPercent}%`} key={index}>
                <Stack direction="column">
                  <ColorBoxStyled
                    style={{
                      backgroundColor: getColor(index),
                    }}
                  >
                    {item.count}
                  </ColorBoxStyled>
                  <LabelBox>{item.label}</LabelBox>
                </Stack>
              </CountBoxStyled>
            );
          })}
        </Stack>
      </Grid>
    </MainGridStyled>
  );
};
