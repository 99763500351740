import {
  Accordion,
  AccordionDetails,
  Box,
  Grid,
  keyframes,
  styled,
} from '@mui/material';

export const VisualDashboardGrid = styled(Grid)(({ theme }) => ({
  container: true,
  rowSpacing: 1,
  columnSpacing: 1,
}));

export const VisualDashboardTitle = styled(Grid)(({ theme }) => ({
  item: true,
  display: 'flex',
  alignItems: 'left',
  justifyContent: 'left',
  xs: 12,
}));

export const VisualDashboardItems = styled(Grid)(({ theme }) => ({
  item: true,
  xs: 12,
  marginBottom: 30,
}));

export const VisualDashboardBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const EditableWidgetAnimation = (size: 'sm' | 'md' | 'lg') => {
  const animation = {
    sm: keyframes`
        0%, 100% {
          transform: rotate(-0.5deg) translateX(0px);
        }
        50% {
          transform: rotate(0.5deg) translateX(0px);
        }
      `,
    md: keyframes`
        0%, 100% {
          transform: rotate(-0.06deg) translateX(0px);
        }
        50% {
          transform: rotate(0.06deg) translateX(0px);
        }
      `,
    lg: keyframes`
        0%, 100% {
          transform: rotate(-0.1deg) translateX(0px);
        }
        50% {
          transform: rotate(0.1deg) translateX(0px);
        }
      `,
  };

  return animation[size];
};

export const EntityTypeChartGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isInEditMode',
})<{
  isInEditMode?: boolean;
}>(({ theme, isInEditMode }) => ({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#007AFF1B',
  borderRadius: '8px',

  animation: `${
    isInEditMode
      ? `${EditableWidgetAnimation('md')} 0.5s ease-in-out infinite`
      : ''
  }`,
}));

export const ChartWidgetBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isInEditMode',
})<{
  isInEditMode?: boolean;
}>(({ theme, isInEditMode }) => ({
  display: 'grid',
  boxShadow: '0px 2px 6px rgba(46, 125, 247, 0.3)',
  borderRadius: '7px',
  marginLeft: '4px',
  marginRight: '4px',
  padding: theme.spacing(1),
  position: 'relative',
  animation: `${
    isInEditMode
      ? `${EditableWidgetAnimation('lg')} 0.5s ease-in-out infinite`
      : ''
  }`,
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    borderBottom: 'none',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
}));
