import { GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';

import { ActionLink } from '../../../../../components/Link/ActionLink/ActionLink';
import {
  getAttributes,
  getJournalEntries,
} from '../../../../../services/arkGL.service';
import { M_DASH_UNICODE } from '../../../../../utils/constants/constants';
import { CurrencyFormat } from '../../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../../utils/hooks/useEffectAsync.hook';
import { SelectedJournalEntry } from '../../../../../utils/types/arkGLJournalEntry.type';
import { DetailsType } from '../../../../../utils/types/form.type';
import { DataGridColDef } from '../../../../../utils/types/listItems';
import { GlViews } from '../../NewGlReportList.hooks';
import {
  CreditRange,
  DebitRange,
  getAllChildLineItemsWithSameFsName,
  getLineItemsForDateCol,
  getTrialBalanceDetailsData,
  getTrialBalanceDetailsDataNew,
  ReportAccount,
  ReportDetailsParams,
  ReportLineItem,
  StartBalanceRange,
  TrialBalanceDetailsParams,
} from '../shared';

type GridItem = {
  id: string;
  postDate: string;
  ledger: string;
  adjustingJournalEntry: string;
  memo: string;
  accountName: string;
  amount: number;
  balance: any;
};

const initialJournalEntry: SelectedJournalEntry = {
  journalEntry: undefined,
  type: undefined,
};

// In pending re-write, consider passing the actual values to the details page via the params, rather than making a second call
// ensure to have consistency with number values on list view,
// currently details view deals with strings instead of number types (12/11/23)
export const useReportDetails = (
  reportParams: ReportDetailsParams,
  reportView: string,
  level1Clicked: boolean,
  zeroSumStartBalance: boolean,
  customStartBalanceClicked: boolean,
  customEndBalanceClicked: boolean,
  customItdClicked: boolean,
  summaryRowClicked: boolean,
  dateRange: any,
  netRow: boolean,
  retainedRow: boolean
) => {
  const [gridData, setGridData] = useState<GridItem[]>([]);
  const [currency, setCurrency] = useState(reportParams.currency);
  const [dateTitle, setDateTitle] = useState('');
  const [currentDecimals, setCurrentDecimals] = useState(reportParams.decimals);
  const [isLoading, setIsloading] = useState(false);
  const [customLevel1, setCustomLevel1] = useState(reportParams.level1Clicked);
  const [journalEntriesList, setJournalEntriesList] = useState([]);
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<SelectedJournalEntry>();

  useEffectAsync(async (isCanceled) => {
    const dateCol = reportParams.dateColInfos;
    const dateRange = {
      name: 'Date Range',
      title: 'Report Range',
      visible: false,
      useLinkButton: false,
      startDate: reportParams.dateRange[0]!,
      endDate: reportParams.dateRange[1]!,
    };

    setDateTitle(dateCol.title);

    const params: TrialBalanceDetailsParams = {
      startDate: dateCol.startDate,
      endDate: dateCol.endDate,
      accounts: reportParams.accountIds,
      glStatus: reportParams.journalEntryStates,
      ledgerIDs: reportParams.ledgerIds,
      attributes: reportParams.attributeIds,
      fsName: reportParams.fsName!,
      summaryRow: reportParams?.summaryRow,
      isCustom: reportParams?.isCustom,
    };

    setIsloading(true);

    const response =
      reportView === GlViews.trialBalance
        ? await getTrialBalanceDetailsDataNew(params, isCanceled, reportView)
        : await getTrialBalanceDetailsData(params, isCanceled);

    if (!response || isCanceled()) return;

    const allJournalEntries = await getJournalEntries(
      reportParams.fundIds[0],
      reportParams.ledgerIds[0]
    );

    setJournalEntriesList(allJournalEntries.items);

    const {
      reportAccounts,
      reportAccountsFiltered: accounts,
      decimals,
      currency,
    } = response;

    let dataList: any[] = [];

    const uniqueParentIds: any = [];

    for (let a = 0; a < accounts.length; a++) {
      const account = accounts[a];
      let lineItems: ReportLineItem[];

      //If custom and level 1 parent is clicked on list view, we need to collect all parent and child relationships then filter

      const groupedColumn =
        dateCol.name.includes('month') ||
        dateCol.name.includes('quarter') ||
        dateCol.name.includes('year')
          ? true
          : false;

      if (reportParams.isCustom) {
        lineItems = getAllChildLineItemsWithSameFsName(account, account.fsName);
        lineItems = getLineItemsForDateCol(lineItems, dateCol);
        if (groupedColumn)
          lineItems = getLineItemsForDateCol(lineItems, dateRange);
      } else {
        lineItems = getLineItemsForDateCol(account.lineItems, dateCol);
        if (groupedColumn)
          lineItems = getLineItemsForDateCol(lineItems, dateRange);
      }

      for (let l = 0; l < lineItems.length; l++) {
        const lineItem = lineItems[l];

        const newRow = {
          id: '',
          postDate: lineItem.date as any,
          ledger: lineItem.ledgerName,
          ledgerId: lineItem.ledgerId,
          adjustingJournalEntry: lineItem.adjustingJournalEntry ? 'Yes' : 'No',
          memo: lineItem.memo,
          accountId: account.accountId,
          accountName: account.accountName,
          amount: lineItem.amount.toString(),
          balance: 0,
          decimals: decimals,
          currencyCode: currency,
          journalEntryNum: lineItem.journalEntryNum,
          journalEntryStatus: lineItem.state,
          fundId: account.fundId,
          type: lineItem.type,
        };

        dataList.push(newRow);
      }

      const { parentAccountId } = account;

      // Make sure to include parent account details for total summary rows
      if (parentAccountId && params.summaryRow) {
        const parentRow: any = reportAccounts.find(
          (acct) => acct.accountId === parentAccountId
        );

        uniqueParentIds.push(account.accountId);

        if (!uniqueParentIds.includes(parentAccountId)) {
          uniqueParentIds.push(parentAccountId);

          const parentLineItems: any = getLineItemsForDateCol(
            parentRow.lineItems,
            dateCol
          );

          for (let l = 0; l < parentLineItems.length; l++) {
            const parentLineItem = parentLineItems[l];
            const newParentRow = {
              id: '',
              postDate: parentLineItem.date as any,
              ledger: parentLineItem.ledgerName,
              ledgerId: parentLineItem.ledgerId,
              adjustingJournalEntry: parentLineItem.adjustingJournalEntry
                ? 'Yes'
                : 'No',
              memo: parentLineItem.memo,
              accountName: parentRow.accountName,
              accountId: account.accountId,
              amount: parentLineItem.amount.toString(),
              balance: 0,
              decimals: decimals,
              currencyCode: currency,
              journalEntryNum: parentLineItem.journalEntryNum,
              journalEntryStatus: parentLineItem.state,
              fundId: parentRow.fundId,
            };

            dataList.push(newParentRow);
          }
        }
      }
    }

    let groupedStartBalance = 0;

    const attribResponse = await getAttributes();
    const expenseAttrib = attribResponse.items.find(
      (a: any) => a.type === 'Expense'
    )!;

    if (
      dateCol.category === 'Yearly' ||
      dateCol.category === 'Quarterly' ||
      dateCol.category === 'Months'
    ) {
      if (reportView === 'balance_sheet') {
        accounts.map((account) => {
          if (isSubtractLineItem(account.accountId)) {
            groupedStartBalance -= account.startBalance;
          } else {
            groupedStartBalance += account.startBalance;
          }
        });
      }
      if (reportView === 'balance_sheet_fs_mapping') {
        groupedStartBalance = calculateCustomGroupStartBalance(accounts);
      }
    }

    dataList.sort((a: any, b: any) => (a.postDate > b.postDate ? -1 : 1));

    // Constant to replace for string values
    const zeroValue = '0';
    // Make sure to recalculate the start balance with ITD values now included with second API call on list view
    let startBalanceSum;

    startBalanceSum =
      zeroSumStartBalance || netRow
        ? zeroValue
        : accounts.reduce(
            (p, c) =>
              (
                parseFloat(p) + parseFloat(c.startBalance.toString())
              ).toString(),
            '0'
          );

    if (
      dateCol.category === 'Yearly' ||
      dateCol.category === 'Quarterly' ||
      dateCol.category === 'Months'
    ) {
      if (
        reportView === 'balance_sheet' ||
        reportView === 'balance_sheet_fs_mapping'
      ) {
        startBalanceSum = groupedStartBalance.toString();
      }
    }

    let currBalance = startBalanceSum;

    for (let i = dataList.length - 1; i >= 0; i--) {
      const dl = dataList[i];

      dl.id = i.toString();

      const amountAsNumber = parseFloat(dl.amount);

      if (reportView === GlViews.trialBalance) {
        const totalItdDebits = dataList
          .slice(dl.id)
          .reduce((prevLi, currValue) => {
            if (currValue.type === 'DEBIT') {
              return parseFloat(currValue.amount) + prevLi;
            }
            return prevLi;
          }, 0);

        const totalItdCredtis = dataList
          .slice(i)
          .reduce((prevLi, currValue) => {
            if (currValue.type === 'CREDIT') {
              return parseFloat(currValue.amount) + prevLi;
            }
            return prevLi;
          }, 0);

        dl.balance = totalItdDebits - totalItdCredtis;
        dl.balance = dl.balance.toString();
      } else {
        if (isSubtractLineItem(dl.accountId)) {
          dl.balance = (parseFloat(currBalance) - amountAsNumber).toString();
        } else {
          dl.balance = (parseFloat(currBalance) + amountAsNumber).toString();
        }
      }

      currBalance = dl.balance;
    }

    if (
      dateCol.name === StartBalanceRange &&
      reportView !== GlViews.trialBalance
    ) {
      startBalanceSum = dataList?.length ? dataList[0].balance : '0';
      if (netRow) startBalanceSum = '0';
      dataList = [];
    }

    if (
      dateCol.name === StartBalanceRange &&
      reportView === GlViews.trialBalance
    ) {
      startBalanceSum = accounts[0].startBalance;
    }

    const startingBalanceLine: GridItem = {
      id: dataList.length.toString(),
      postDate: 'Starting Balance',
      ledger: M_DASH_UNICODE,
      adjustingJournalEntry: M_DASH_UNICODE,
      memo: M_DASH_UNICODE,
      accountName: M_DASH_UNICODE,
      amount: M_DASH_UNICODE as any,
      balance: startBalanceSum,
    };

    // Do not include starting balance details line if column cliked includes:
    if (
      !(
        dateCol.category === 'Quarterly' ||
        dateCol.category === 'Months' ||
        dateCol.category === 'Yearly' ||
        dateCol.name === CreditRange ||
        dateCol.name === DebitRange ||
        dateCol.name.includes('selected')
      )
    ) {
      dataList.push(startingBalanceLine);
    }

    if (
      dateCol.category === 'Yearly' ||
      dateCol.category === 'Quarterly' ||
      dateCol.category === 'Months'
    ) {
      if (
        reportView === 'balance_sheet' ||
        reportView === 'balance_sheet_fs_mapping'
      ) {
        dataList.push(startingBalanceLine);
      }
    }

    setGridData(dataList);
    setIsloading(false);

    function isSubtractLineItem(accountId: string) {
      const account = reportAccounts.find((a) => a.accountId === accountId)!;

      return expenseAttrib.id === account.attributeId;
    }

    function calculateCustomGroupStartBalance(accounts: ReportAccount[]) {
      let result = 0;

      function addBalances(account: ReportAccount) {
        if (isSubtractLineItem(account.accountId)) {
          result -= account.startBalance;
        } else {
          result += account.startBalance;
        }

        if (account.childAccounts && account.childAccounts.length > 0) {
          for (const child of account.childAccounts) {
            addBalances(child);
          }
        }
      }

      for (const account of accounts) {
        addBalances(account);
      }

      return result;
    }
  }, []);

  const dataGridColDefs: DataGridColDef[] = [
    {
      index: 1,
      field: 'postDate',
      editable: false,
      disableReorder: true,
      headerName: 'Date',
      align: 'left',
      width: 200,
      maxWidth: 200,
      sortable: false,
      resizable: true,
    },
    {
      index: 2,
      field: 'ledger',
      editable: false,
      disableReorder: true,
      headerName: 'Ledger',
      align: 'left',
      width: 400,
      sortable: false,
      resizable: true,
    },
    {
      index: 3,
      field: 'journalEntryNum',
      editable: false,
      disableReorder: true,
      headerName: 'Journal Number',
      align: 'left',
      width: 50,
      minWidth: 50,
      sortable: false,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return (
          <ActionLink
            id={`journal_entry_num_${params.value}`}
            onClick={() => handleViewJournalEntryDetails(params)}
          >
            {params.value}
          </ActionLink>
        );
      },
    },
    {
      index: 4,
      field: 'journalEntryStatus',
      editable: false,
      disableReorder: true,
      headerName: 'Journal Status',
      align: 'left',
      width: 50,
      minWidth: 50,
      sortable: false,
      resizable: true,
    },
    {
      index: 5,
      field: 'adjustingJournalEntry',
      editable: false,
      disableReorder: true,
      headerName: 'Is Adjusting Journal Entry',
      align: 'left',
      width: 50,
      minWidth: 50,
      sortable: false,
      resizable: true,
    },
    {
      index: 6,
      field: 'memo',
      editable: false,
      disableReorder: true,
      headerName: 'Memo',
      align: 'left',
      width: 800,
      sortable: false,
      resizable: true,
    },
    {
      index: 7,
      field: 'accountName',
      editable: false,
      disableReorder: true,
      headerName: 'Account',
      align: 'left',
      width: 400,
      sortable: false,
      resizable: true,
    },
    {
      index: 8,
      field: 'amount',
      editable: false,
      disableReorder: true,
      headerName: 'Amount',
      align: 'right',
      renderCell: renderNumberCell,
      width: 400,
      sortable: false,
      resizable: true,
    },
    {
      index: 9,
      field: 'balance',
      editable: false,
      disableReorder: true,
      headerName: 'Balance',
      align: 'right',
      renderCell: renderNumberCell,
      width: 400,
      sortable: false,
      resizable: true,
    },
  ];

  if (reportView === GlViews.trialBalance) {
    const amountColumnIndex = dataGridColDefs.findIndex(
      (col) => col.field === 'amount'
    );

    const newColumn: DataGridColDef = {
      index: 8,
      field: 'type',
      editable: false,
      disableReorder: true,
      headerName: 'Type',
      align: 'left',
      width: 200,
      sortable: false,
      resizable: true,
      renderCell: (params) => {
        if (params.value === 'CREDIT') return 'Credit';
        if (params.value === 'DEBIT') return 'Debit';
        return '';
      },
    };

    dataGridColDefs.splice(amountColumnIndex, 0, newColumn);

    dataGridColDefs[amountColumnIndex + 1].index = 9;
    dataGridColDefs[amountColumnIndex + 2].index = 10;
  }

  function renderNumberCell(params: GridRenderCellParams<any, any, any>) {
    const value = parseFloat(params.value);

    if (!isNaN(params.value))
      return CurrencyFormat(currency, currentDecimals).format(value);
    else return params.value;
  }

  function handleViewJournalEntryDetails(params: any) {
    const selectedEntry = journalEntriesList.find(
      (item: any) => item.number === params.value
    );

    if (selectedEntry) {
      setSelectedJournalEntry({
        journalEntry: selectedEntry,
        type: DetailsType.Edit,
      });
    }
  }

  function onJournalEntryPanelClose() {
    setIsloading(true);
    setSelectedJournalEntry(initialJournalEntry);
    fetchJournals();
    setIsloading(false);
  }

  const fetchJournals = async () => {
    const allJournalEntries = await getJournalEntries(
      reportParams.fundIds[0],
      reportParams.ledgerIds[0]
    );

    setJournalEntriesList(allJournalEntries.items);
  };

  return {
    isLoading,
    dataGridColDefs,
    gridData,
    dateTitle,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
  };
};
