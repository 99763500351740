import { Theme, useTheme } from '@mui/material/styles';

export const chartsTooltipValueFormatter = (
  value: number | null,
  currency: string
) => {
  if (value === null) return '';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(Math.round(value));
};

export function getColor(itemIndex: number) {
  const theme = useTheme();

  let color = '';
  const colorID = itemIndex % 18;

  switch (colorID) {
    case 0:
      color = theme.palette.common.visUltramarineBlue;
      break;

    case 1:
      color = theme.palette.common.visMidnightBlue;
      break;

    case 2:
      color = theme.palette.common.visTealBlue;
      break;

    case 3:
      color = theme.palette.common.visDarkSlateBlue;
      break;

    case 4:
      color = theme.palette.common.visBrightBlue;
      break;

    case 5:
      color = theme.palette.common.visNavyBlue;
      break;

    case 6:
      color = theme.palette.common.visRoyalBlue;
      break;

    case 7:
      color = theme.palette.common.visCharcoalBlue;
      break;

    case 8:
      color = theme.palette.common.visElectricBlue;
      break;

    case 9:
      color = theme.palette.common.visDeepSeaBlue;
      break;

    case 10:
      color = theme.palette.common.visLightTan;
      break;

    case 11:
      color = theme.palette.common.visMutedCopper;
      break;

    case 12:
      color = theme.palette.common.visLightAqua;
      break;

    case 13:
      color = theme.palette.common.visSoftCoral;
      break;

    case 14:
      color = theme.palette.common.visSkyBlue;
      break;

    case 15:
      color = theme.palette.common.visLightSalmonPink;
      break;

    case 16:
      color = theme.palette.common.visPeach;
      break;

    case 17:
      color = theme.palette.common.visPaleMauve;
      break;
  }

  return color;
}
