import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PowerInputRoundedIcon from '@mui/icons-material/PowerInputRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { DashboardWidget } from '../../../utils/types/visualDashboard.type';
import {
  AddWidgetButton,
  AddWidgetsMenu,
  DashboardWidgetsConfigBtn,
  ViewConfigBox,
} from './DashboardViewConfig.styles';

type DashboardViewConfigProps = {
  isInEditMode: boolean;
  setIsEditingWidgets: (value: boolean) => void;
  dashboardWidgets: DashboardWidget[];
  saveDashboardWidgets: (dashboardWidgets: DashboardWidget[]) => void;
  editedWidgets: DashboardWidget[];
  handleWidgetVisibilityChange: (name: string, visible: boolean) => void;
};

const WidgetMenuItem: FC<{
  widget: DashboardWidget;
  handleWidgetVisibilityChange: (name: string, visible: boolean) => void;
}> = ({ widget, handleWidgetVisibilityChange }) => {
  const iconMap: Record<string, JSX.Element> = {
    entityTypeByFund: <PowerInputRoundedIcon />,
    committedByFund: <DonutSmallOutlinedIcon />,
    totalValueDistributions: <LeaderboardOutlinedIcon />,
  };

  const titleMap: Record<string, string> = {
    entityTypeByFund: 'Entity Type by Fund',
    committedByFund: 'Funds - Total Value',
    totalValueDistributions: 'Distributions Chart',
  };

  return (
    <>
      {widget.name in titleMap && <Divider />}
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          handleWidgetVisibilityChange(widget.name, true);
        }}
      >
        <ListItemIcon>
          {iconMap[widget.name] || <AddCircleOutlineOutlinedIcon />}
        </ListItemIcon>
        {titleMap[widget.name] || widget.title}
      </MenuItem>
    </>
  );
};

export const DashboardViewConfig: FC<DashboardViewConfigProps> = ({
  isInEditMode,
  setIsEditingWidgets,
  editedWidgets,
  handleWidgetVisibilityChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuWidgets, setMenuWidgets] = useState<DashboardWidget[]>([]);

  useEffect(() => {
    if (!menuWidgets.length) setAnchorEl(null);
  }, [menuWidgets]);

  useEffect(() => {
    setMenuWidgets(editedWidgets.filter((widget) => !widget.visible));
  }, [editedWidgets]);

  const toggleEditMode = () => setIsEditingWidgets(!isInEditMode);

  return (
    <ViewConfigBox>
      <DashboardWidgetsConfigBtn
        isInEditMode={isInEditMode}
        onClick={toggleEditMode}
      >
        <SettingsOutlinedIcon color={isInEditMode ? 'inherit' : 'info'} />
      </DashboardWidgetsConfigBtn>

      {isInEditMode && (
        <>
          <AddWidgetButton
            isVisible
            onClick={(e) => setAnchorEl(e.currentTarget)}
            disabled={!menuWidgets.length}
          >
            <AddOutlinedIcon />
          </AddWidgetButton>
          <AddWidgetsMenu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Box
              px={2}
              py={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>
                <strong>Add to dashboard</strong>
              </Typography>
            </Box>
            <Divider />
            {menuWidgets.map((widget) => (
              <WidgetMenuItem
                key={widget.name}
                widget={widget}
                handleWidgetVisibilityChange={handleWidgetVisibilityChange}
              />
            ))}
          </AddWidgetsMenu>
        </>
      )}
    </ViewConfigBox>
  );
};
